.after-review-img {
  width: 100%;
  height: 0;
  padding-bottom: 60%;
  background-image: url('../../img/detalis/after-review-img.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.naver-map {
  width: 100%;
  height: 300px;
  margin-bottom: 24px;
}

.map-container-title {
  font-size: 11px;
  font-weight: 600;
  color: #5f0080;
  text-align: center;
  margin-bottom: 12px;
}
.map-container-text-addr {
  color: #5f0080;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  line-height: 1.4;
  margin-bottom: 24px;
}
.map-container-text-img {
  width: 100%;
  height: 0;
  padding-bottom: 80%;
  background-image: url('../../img/detalis/map-img.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 24px;
}
.map-container-text-desc {
  padding: 12px 18px;
  font-size: 12px;
  color: #5f0080;
  margin-bottom: 24px;
}

.map-container-text-link {
  display: block;
  margin: 0 auto;
  width: 60%;
  padding: 12px 0;
  line-height: 1.3;
  background-color: #c181fb;
  color: white;
  text-align: center;
  font-size: 16px;
  border-radius: 20px;
}

.map-container-text-link strong {
  display: none;
}

@media screen and (min-width: 994px) {
  .after-review-img {
    width: 100%;
    height: 0;
    padding-bottom: 60%;
  }
  .map-container {
    padding: 0 48px;
    position: relative;
  }

  .map-container-title {
    display: none;
  }

  .naver-map {
    width: 50%;
    height: 500px;
  }

  .map-container-text {
    width: 40%;
    position: absolute;
    right: 30px;
    top: -15px;
    white-space: nowrap;
  }
  .map-container-text-addr {
    display: block;
    font-size: 24px;
    line-height: 1.7;
    letter-spacing: -0.02em;
    margin-bottom: 24px;
  }

  .map-container-text-desc {
    margin-bottom: 24px;
  }

  .map-container-text-desc,
  .map-container-text-bus {
    padding: 0;
    font-size: 15px;
    letter-spacing: -0.05em;
  }

  .map-container-text-link {
    margin-top: 100px;
    font-size: 34px;
    white-space: nowrap;
    padding: 24px 0;
    border-radius: 40px;
  }
  .map-container-text-link strong {
    display: inline;
  }

  .map-container-text-link span {
    display: none;
  }
}
