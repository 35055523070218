.rehabilitation-intro {
  padding: 0 3px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 24px;
}

.rehabilitation-intro-text-title {
  width: 100%;
  font-size: 16px;
  letter-spacing: -0.02em;
  line-height: 1.3;
  color: #5f0080;
  font-weight: 500;
  margin-bottom: 12px;
}

.rehabilitation-intro-text-desc {
  font-size: 10px;
  letter-spacing: 0;
  line-height: 1.6;
  color: #707070;
}

.rehabilitation-intro-img {
  width: 40%;
  height: 0;
  padding-bottom: 32%;
  border-radius: 20px;
  background-image: url('../../img/rehabilitation-intro/rehabilitation-intro-logo.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media screen and (min-width: 798px) {
  .rehabilitation-intro {
    margin-bottom: 70px;
  }

  .rehabilitation-intro-text-title {
    font-size: 36px;
    margin-bottom: 24px;
  }
  .rehabilitation-intro-text-desc {
    font-size: 24px;
    letter-spacing: -0.03em;
  }
}
