.care-container-main-img {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-image: url('../../img/care-intro/care-main-img.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.care-container-box-item {
  margin-bottom: 24px;
}

.care-container-box-item-img1,
.care-container-box-item-img2,
.care-container-box-item-img3,
.care-container-box-item-img4,
.care-container-box-item-img5,
.care-container-box-item-img6,
.care-container-box-item-img7 {
  width: 100%;
  height: 250px;
  max-width: 500px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: 1px solid #5f0080;
  margin: 0 auto;
  border-radius: 12px;
  margin-bottom: 12px;
}

.care-container-box-item-img1 {
  background-image: url('../../img/care-intro/care-1.jpg');
}
.care-container-box-item-img2 {
  background-image: url('../../img/care-intro/care-2.jpg');
}
.care-container-box-item-img3 {
  background-image: url('../../img/care-intro/care-3.jpg');
}
.care-container-box-item-img4 {
  background-image: url('../../img/care-intro/care-4.jpg');
}
.care-container-box-item-img5 {
  background-image: url('../../img/care-intro/care-5.jpg');
}

.care-container-box-item p {
  margin-top: 3px;
  text-align: center;
  color: #5f0080;
  font-size: 12px;
  font-weight: 500;
}

.care-slick-container-main {
  display: none;
}

@media screen and (min-width: 994px) {
  .care-slick-container-main {
    margin-bottom: 100px;
  }
  .care-container-main-img {
    padding-bottom: 60%;
    background-image: url('../../img/care-intro/care-main-img-desk.jpg');
    margin-bottom: 70px;
  }

  .care-main {
    justify-content: space-around;
    align-items: center;
    margin-bottom: 70px;
    margin-top: 70px;
  }

  .care-main-desc {
    font-size: 28px;
    letter-spacing: -0.03em;
    white-space: nowrap;
    margin-bottom: 36px;
  }

  .care-intro-sub-notice {
    display: none;
  }

  .care-slick-container-main {
    display: block;
  }

  .care-container-box-items {
    display: none;
  }

  .care-container-box-item-img1 {
    background-image: url('../../img/rehabilitation-intro/rehabilitation-intro-1.jpg');
  }
  .care-container-box-item-img2 {
    background-image: url('../../img/rehabilitation-intro/rehabilitation-intro-2.jpg');
  }
  .care-container-box-item-img3 {
    background-image: url('../../img/rehabilitation-intro/rehabilitation-intro-3.jpg');
  }
  .care-container-box-item-img4 {
    background-image: url('../../img/rehabilitation-intro/rehabilitation-intro-4.jpg');
  }
}
