.rehabilitation-slick-container,
.care-slick-container-main,
.care-slick-container-intro {
  margin: 0 8px 56px;
  padding: 0 5px;
}

.slick-slide img {
  margin: 0 auto;
}

.slick-track {
  margin-bottom: 36px;
}

.slick-track img {
  width: 100%;
  border-radius: 20px;
}

.slick-dots li {
  margin-right: 30px;
}

.slick-dots li a {
  display: block;
}

.slick-dots li a img {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

.slick-slide div {
  outline: none;
}

/* care */

.care-slick-container-main p,
.rehabilitation-slick-container p,
.care-slick-container-intro p {
  margin-top: 10px;
  text-align: center;
  color: #5f0080;
}
.care-slick-container-main .slick-track,
.care-slick-container-intro .slick-track {
  margin-bottom: 36px;
}

.care-slick {
  display: flex;
}
.care-slick li {
  margin-right: 30px;
}

.care-slick li a img {
  width: 50px;
  height: 50px;
}

@media screen and (min-width: 994px) {
  .rehabilitation-slick-container,
  .care-slick-container-intro,
  .care-slick-container-main {
    padding: 0 48px;
    margin-bottom: 100px;
  }
  .slick-next {
    margin-right: 160px;
  }
  .slick-prev {
    margin-left: 150px;
  }

  .slick-slide img {
    margin: 0 auto;
  }

  .slick-track img {
    width: 50%;
  }

  .rehabilitation-slick-container .slick-track p {
    margin-bottom: 100px;
  }
  .slick-arrow:before {
    font-size: 33px;
    color: #5f0080;
  }

  .slick-dots li {
    margin-right: 140px;
  }

  .slick-dots li a img {
    width: 150px;
    height: 150px;
  }

  /* care */

  .care-slick-container-main p,
  .care-slick-container-intro p,
  .rehabilitation-slick-container p {
    font-size: 16px;
    font-weight: 500;
  }

  .care-slick-container-main .slick-track,
  .care-slick-container-intro .slick-track {
    margin-bottom: 150px;
  }

  .care-slick li {
    margin-right: 140px;
  }
}
