/* Facility-intro */
.facility-intro {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 36px;
  padding: 0 2px;
}

.facility-intro-text {
  margin-right: 10px;
  white-space: nowrap;
}

.facility-intro-title {
  font-size: 20px;
  color: #5f0080;
  letter-spacing: -0.022em;
  line-height: 1.4;
  margin-bottom: 8px;
  font-weight: 500;
}

.facility-intro-desc {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 1.6;
  color: #707070;
  margin-bottom: 8px;
}

.facility-intro-sub-bar {
  font-size: 8px;
  display: inline-block;
  background-color: #5f0080;
  padding: 5px 14px;
  border-radius: 16px;
  color: #fff;
  margin-bottom: 8px;
}

.facility-intro-sub-notice {
  display: block;
  font-size: 10px;
  letter-spacing: -0.05em;
  line-height: 1.6;
  color: #707070;
}

.facility-intro-img {
  width: 50%;
  height: 0;
  padding-bottom: 50%;
  border-radius: 10px;
  background-image: url('../../img/facility-intro/facility-intro-logo.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media screen and (min-width: 994px) {
  .facility-intro {
    justify-content: space-around;
    align-items: center;
    margin-bottom: 70px;
  }

  .facility-intro-text {
    width: auto;
  }
  .facility-intro-title {
    font-size: 36px;
    letter-spacing: -0.03em;
    font-weight: 500;
    margin-bottom: 36px;
  }
  .facility-intro-desc {
    font-size: 28px;
    letter-spacing: -0.03em;
    white-space: nowrap;
    margin-bottom: 48px;
  }
  .facility-intro-sub-bar {
    font-size: 24px;
    padding: 18px 36px;
  }
  .facility-intro-sub-notice {
    display: none;
  }

  .facility-img {
    padding: 0 24px;
  }

  .facility-intro-img {
    width: 40%;
    padding-bottom: 35%;
    background-image: url('../../img/facility-intro/facility-intro-logo.jpg');
  }
}

/* Facility-img */

.facility-img {
  padding: 0 2px;
  margin-bottom: 48px;
}

.facility-img-text-title {
  font-size: 20px;
  letter-spacing: -0.022em;
  color: #5f0080;
  line-height: 1.4;
  margin-bottom: 16px;
  font-weight: 500;
}

.facility-img-text-desc {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 1.6;
  color: #707070;
  margin-bottom: 16px;
}

.facility-img-img {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
  justify-content: center;
}

.facility-img-img-item1,
.facility-img-img-item2,
.facility-img-img-item3,
.facility-img-img-item4,
.facility-img-img-item5,
.facility-img-img-item6 {
  width: 48%;
  height: 0;
  padding-bottom: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: 1px solid #5f0080;
  border-radius: 12px;
}

.facility-img-img-item1 {
  background-image: url('../../img/facility-intro/facility-items/facility-1.jpg');
  margin-right: 8px;
  margin-bottom: 8px;
}
.facility-img-img-item2 {
  background-image: url('../../img/facility-intro/facility-items/facility-2.jpg');
  margin-bottom: 8px;
}
.facility-img-img-item3 {
  background-image: url('../../img/facility-intro/facility-items/facility-3.jpg');
  margin-right: 8px;
  margin-bottom: 8px;
}
.facility-img-img-item4 {
  background-image: url('../../img/facility-intro/facility-items/facility-4.jpg');
  margin-bottom: 8px;
}
.facility-img-img-item5 {
  background-image: url('../../img/facility-intro/facility-items/facility-5.jpg');
  margin-right: 8px;
}
.facility-img-img-item6 {
  background-image: url('../../img/facility-intro/facility-items/facility-6.jpg');
}

@media screen and (min-width: 994px) {
  .facility-img {
    background-color: #eef2f6;
    padding: 36px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 100px;
  }

  .facility-img-text-title-br {
    display: none;
  }

  .facility-img-text-title {
    font-size: 40px;
  }

  .facility-img-text-desc {
    font-size: 30px;
    margin-bottom: 24px;
  }

  .facility-img-img-item1,
  .facility-img-img-item2,
  .facility-img-img-item3,
  .facility-img-img-item4,
  .facility-img-img-item5,
  .facility-img-img-item6 {
    height: 0;
    padding-bottom: 50%;
  }

  .facility-img-img-item1 {
    margin-right: 15px;
    margin-bottom: 15px;
  }
  .facility-img-img-item2 {
    margin-bottom: 15px;
  }
  .facility-img-img-item3 {
    margin-right: 15px;
    margin-bottom: 15px;
  }
  .facility-img-img-item4 {
    margin-bottom: 15px;
  }
  .facility-img-img-item5 {
    margin-right: 15px;
  }
}
