.facility-container-blog-title {
  background-color: #5f0080;
  color: #fff;
  text-align: center;
  border-radius: 20px;
  padding: 12px 24px;
  margin-bottom: 16px;
}

.facility-container-blog-list {
  padding: 0 10px;
  margin-bottom: 24px;
}

.facility-container-blog-list-item {
  margin-bottom: 12px;
}
.facility-container-blog-list-item:last-child {
  margin-bottom: 0;
}
.facility-container-blog-list a {
  display: inline-block;
  font-size: 14px;
  color: #707070;
  text-decoration: underline;
  margin-left: 10px;
}

.facility-container-blog-list-item span {
  font-size: 16px;
  color: #5f0080;
}
