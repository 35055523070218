.facility-container-main-img {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-image: url('../../img/facility-intro/facility-main-img.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.facility-container-box-item-text-title {
  font-size: 20px;
  color: #5f0080;
  letter-spacing: -0.02em;
  line-height: 1.2;
  margin-bottom: 10px;
  font-weight: 500;
}
.facility-container-box-item-text-desc {
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: 0;
  margin-bottom: 15px;
  color: #707070;
  letter-spacing: -0.03em;
}

.facility-container-box-item:first-child {
  margin-top: 25px;
}

.facility-container-box-item-img1,
.facility-container-box-item-img2,
.facility-container-box-item-img3,
.facility-container-box-item-img4 {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 24px;
  border: 0;
  margin-bottom: 48px;
}

.facility-container-box-item-img4 {
  margin-bottom: 20px;
}

.facility-container-box-item-img1 {
  background-image: url('../../img/facility-intro/facility-items/facility-9.jpg');
}
.facility-container-box-item-img2 {
  background-image: url('../../img/facility-intro/facility-items/facility-10.jpg');
}
.facility-container-box-item-img3 {
  background-image: url('../../img/facility-intro/facility-items/facility-8.jpg');
}
.facility-container-box-item-img4 {
  background-image: url('../../img/facility-intro/facility-items/facility-6.jpg');
}

.facility-container-info h1 {
  font-size: 18px;
  background-color: #82061b;
  color: #fff;
  text-align: center;
  border-radius: 24px;
  padding: 12px 24px;
  margin-bottom: 24px;
}

@media screen and (min-width: 994px) {
  .facility-container-main-img {
    padding-bottom: 60%;
    background-image: url('../../img/facility-intro/facility-main-img-desk.jpg');
  }

  .facility-container-box-item:first-child {
    display: none;
  }

  .facility-container-box-items {
    display: flex;
    padding: 12px;
    margin-bottom: 48px;
  }
  .facility-container-box-item {
    margin-right: 33px;
    width: 33%;
  }
  .facility-container-box-item:last-child {
    margin-right: 0;
  }

  .facility-container-box-item-text-title {
    text-align: center;
    font-size: 32px;
    padding: 0 12px;
    margin-bottom: 18px;
  }
  .facility-container-box-item-text-desc {
    font-size: 15px;
    line-height: 1.3684210526;
    padding: 0 18px;
    letter-spacing: -0.05em;
  }

  .facility-container-blog-title {
    width: 50%;
    margin: 48px auto;
    font-size: 30px;
  }

  .facility-container-blog-list {
    margin-bottom: 48px;
  }

  .facility-container-blog-list-item {
    margin-bottom: 32px;
  }

  .facility-container-blog-list-item:last-child {
    margin-bottom: 0;
  }

  .facility-container-blog-list a {
    font-size: 28px;
  }

  .facility-container-blog-list-item span {
    font-size: 30px;
  }

  .facility-container-info h1 {
    font-size: 33px;
    width: 50%;
    margin: 56px auto 70px;
  }
}
