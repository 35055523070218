@import url(https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap);
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
} /*!
   * Bootstrap Grid v4.4.1 (https://getbootstrap.com/)
   * Copyright 2011-2019 The Bootstrap Authors
   * Copyright 2011-2019 Twitter, Inc.
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
   */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}
.row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col {
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  max-width: 100%;
}
.row-cols-1 > * {
  -webkit-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
}
.row-cols-2 > * {
  -webkit-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}
.row-cols-3 > * {
  -webkit-flex: 0 0 33.3333333333%;
          flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.row-cols-4 > * {
  -webkit-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%;
}
.row-cols-5 > * {
  -webkit-flex: 0 0 20%;
          flex: 0 0 20%;
  max-width: 20%;
}
.row-cols-6 > * {
  -webkit-flex: 0 0 16.6666666667%;
          flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.col-auto {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.col-1 {
  -webkit-flex: 0 0 8.3333333333%;
          flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}
.col-2 {
  -webkit-flex: 0 0 16.6666666667%;
          flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.col-3 {
  -webkit-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  -webkit-flex: 0 0 33.3333333333%;
          flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.col-5 {
  -webkit-flex: 0 0 41.6666666667%;
          flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}
.col-6 {
  -webkit-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  -webkit-flex: 0 0 58.3333333333%;
          flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}
.col-8 {
  -webkit-flex: 0 0 66.6666666667%;
          flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}
.col-9 {
  -webkit-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  -webkit-flex: 0 0 83.3333333333%;
          flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}
.col-11 {
  -webkit-flex: 0 0 91.6666666667%;
          flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}
.col-12 {
  -webkit-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  -webkit-order: -1;
          order: -1;
}
.order-last {
  -webkit-order: 13;
          order: 13;
}
.order-0 {
  -webkit-order: 0;
          order: 0;
}
.order-1 {
  -webkit-order: 1;
          order: 1;
}
.order-2 {
  -webkit-order: 2;
          order: 2;
}
.order-3 {
  -webkit-order: 3;
          order: 3;
}
.order-4 {
  -webkit-order: 4;
          order: 4;
}
.order-5 {
  -webkit-order: 5;
          order: 5;
}
.order-6 {
  -webkit-order: 6;
          order: 6;
}
.order-7 {
  -webkit-order: 7;
          order: 7;
}
.order-8 {
  -webkit-order: 8;
          order: 8;
}
.order-9 {
  -webkit-order: 9;
          order: 9;
}
.order-10 {
  -webkit-order: 10;
          order: 10;
}
.order-11 {
  -webkit-order: 11;
          order: 11;
}
.order-12 {
  -webkit-order: 12;
          order: 12;
}
.offset-1 {
  margin-left: 8.3333333333%;
}
.offset-2 {
  margin-left: 16.6666666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.3333333333%;
}
.offset-5 {
  margin-left: 41.6666666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.3333333333%;
}
.offset-8 {
  margin-left: 66.6666666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.3333333333%;
}
.offset-11 {
  margin-left: 91.6666666667%;
}
@media (min-width: 576px) {
  .col-sm {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    -webkit-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    -webkit-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    -webkit-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -webkit-flex: 0 0 8.3333333333%;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    -webkit-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    -webkit-flex: 0 0 41.6666666667%;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-flex: 0 0 58.3333333333%;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    -webkit-flex: 0 0 66.6666666667%;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-flex: 0 0 83.3333333333%;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    -webkit-flex: 0 0 91.6666666667%;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -webkit-order: -1;
            order: -1;
  }
  .order-sm-last {
    -webkit-order: 13;
            order: 13;
  }
  .order-sm-0 {
    -webkit-order: 0;
            order: 0;
  }
  .order-sm-1 {
    -webkit-order: 1;
            order: 1;
  }
  .order-sm-2 {
    -webkit-order: 2;
            order: 2;
  }
  .order-sm-3 {
    -webkit-order: 3;
            order: 3;
  }
  .order-sm-4 {
    -webkit-order: 4;
            order: 4;
  }
  .order-sm-5 {
    -webkit-order: 5;
            order: 5;
  }
  .order-sm-6 {
    -webkit-order: 6;
            order: 6;
  }
  .order-sm-7 {
    -webkit-order: 7;
            order: 7;
  }
  .order-sm-8 {
    -webkit-order: 8;
            order: 8;
  }
  .order-sm-9 {
    -webkit-order: 9;
            order: 9;
  }
  .order-sm-10 {
    -webkit-order: 10;
            order: 10;
  }
  .order-sm-11 {
    -webkit-order: 11;
            order: 11;
  }
  .order-sm-12 {
    -webkit-order: 12;
            order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    -webkit-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    -webkit-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    -webkit-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -webkit-flex: 0 0 8.3333333333%;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    -webkit-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    -webkit-flex: 0 0 41.6666666667%;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-flex: 0 0 58.3333333333%;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    -webkit-flex: 0 0 66.6666666667%;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-flex: 0 0 83.3333333333%;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    -webkit-flex: 0 0 91.6666666667%;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -webkit-order: -1;
            order: -1;
  }
  .order-md-last {
    -webkit-order: 13;
            order: 13;
  }
  .order-md-0 {
    -webkit-order: 0;
            order: 0;
  }
  .order-md-1 {
    -webkit-order: 1;
            order: 1;
  }
  .order-md-2 {
    -webkit-order: 2;
            order: 2;
  }
  .order-md-3 {
    -webkit-order: 3;
            order: 3;
  }
  .order-md-4 {
    -webkit-order: 4;
            order: 4;
  }
  .order-md-5 {
    -webkit-order: 5;
            order: 5;
  }
  .order-md-6 {
    -webkit-order: 6;
            order: 6;
  }
  .order-md-7 {
    -webkit-order: 7;
            order: 7;
  }
  .order-md-8 {
    -webkit-order: 8;
            order: 8;
  }
  .order-md-9 {
    -webkit-order: 9;
            order: 9;
  }
  .order-md-10 {
    -webkit-order: 10;
            order: 10;
  }
  .order-md-11 {
    -webkit-order: 11;
            order: 11;
  }
  .order-md-12 {
    -webkit-order: 12;
            order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    -webkit-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    -webkit-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    -webkit-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -webkit-flex: 0 0 8.3333333333%;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    -webkit-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    -webkit-flex: 0 0 41.6666666667%;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-flex: 0 0 58.3333333333%;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    -webkit-flex: 0 0 66.6666666667%;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-flex: 0 0 83.3333333333%;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    -webkit-flex: 0 0 91.6666666667%;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -webkit-order: -1;
            order: -1;
  }
  .order-lg-last {
    -webkit-order: 13;
            order: 13;
  }
  .order-lg-0 {
    -webkit-order: 0;
            order: 0;
  }
  .order-lg-1 {
    -webkit-order: 1;
            order: 1;
  }
  .order-lg-2 {
    -webkit-order: 2;
            order: 2;
  }
  .order-lg-3 {
    -webkit-order: 3;
            order: 3;
  }
  .order-lg-4 {
    -webkit-order: 4;
            order: 4;
  }
  .order-lg-5 {
    -webkit-order: 5;
            order: 5;
  }
  .order-lg-6 {
    -webkit-order: 6;
            order: 6;
  }
  .order-lg-7 {
    -webkit-order: 7;
            order: 7;
  }
  .order-lg-8 {
    -webkit-order: 8;
            order: 8;
  }
  .order-lg-9 {
    -webkit-order: 9;
            order: 9;
  }
  .order-lg-10 {
    -webkit-order: 10;
            order: 10;
  }
  .order-lg-11 {
    -webkit-order: 11;
            order: 11;
  }
  .order-lg-12 {
    -webkit-order: 12;
            order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    -webkit-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    -webkit-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    -webkit-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -webkit-flex: 0 0 8.3333333333%;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    -webkit-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -webkit-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    -webkit-flex: 0 0 41.6666666667%;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -webkit-flex: 0 0 58.3333333333%;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    -webkit-flex: 0 0 66.6666666667%;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -webkit-flex: 0 0 83.3333333333%;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    -webkit-flex: 0 0 91.6666666667%;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -webkit-order: -1;
            order: -1;
  }
  .order-xl-last {
    -webkit-order: 13;
            order: 13;
  }
  .order-xl-0 {
    -webkit-order: 0;
            order: 0;
  }
  .order-xl-1 {
    -webkit-order: 1;
            order: 1;
  }
  .order-xl-2 {
    -webkit-order: 2;
            order: 2;
  }
  .order-xl-3 {
    -webkit-order: 3;
            order: 3;
  }
  .order-xl-4 {
    -webkit-order: 4;
            order: 4;
  }
  .order-xl-5 {
    -webkit-order: 5;
            order: 5;
  }
  .order-xl-6 {
    -webkit-order: 6;
            order: 6;
  }
  .order-xl-7 {
    -webkit-order: 7;
            order: 7;
  }
  .order-xl-8 {
    -webkit-order: 8;
            order: 8;
  }
  .order-xl-9 {
    -webkit-order: 9;
            order: 9;
  }
  .order-xl-10 {
    -webkit-order: 10;
            order: 10;
  }
  .order-xl-11 {
    -webkit-order: 11;
            order: 11;
  }
  .order-xl-12 {
    -webkit-order: 12;
            order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: -webkit-flex !important;
  display: flex !important;
}
.d-inline-flex {
  display: -webkit-inline-flex !important;
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -webkit-flex !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -webkit-flex !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -webkit-flex !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -webkit-flex !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -webkit-flex !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
  }
}
.flex-row {
  -webkit-flex-direction: row !important;
          flex-direction: row !important;
}
.flex-column {
  -webkit-flex-direction: column !important;
          flex-direction: column !important;
}
.flex-row-reverse {
  -webkit-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important;
}
.flex-column-reverse {
  -webkit-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important;
}
.flex-wrap {
  -webkit-flex-wrap: wrap !important;
          flex-wrap: wrap !important;
}
.flex-nowrap {
  -webkit-flex-wrap: nowrap !important;
          flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  -webkit-flex-wrap: wrap-reverse !important;
          flex-wrap: wrap-reverse !important;
}
.flex-fill {
  -webkit-flex: 1 1 auto !important;
          flex: 1 1 auto !important;
}
.flex-grow-0 {
  -webkit-flex-grow: 0 !important;
          flex-grow: 0 !important;
}
.flex-grow-1 {
  -webkit-flex-grow: 1 !important;
          flex-grow: 1 !important;
}
.flex-shrink-0 {
  -webkit-flex-shrink: 0 !important;
          flex-shrink: 0 !important;
}
.flex-shrink-1 {
  -webkit-flex-shrink: 1 !important;
          flex-shrink: 1 !important;
}
.justify-content-start {
  -webkit-justify-content: flex-start !important;
          justify-content: flex-start !important;
}
.justify-content-end {
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important;
}
.justify-content-center {
  -webkit-justify-content: center !important;
          justify-content: center !important;
}
.justify-content-between {
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important;
}
.justify-content-around {
  -webkit-justify-content: space-around !important;
          justify-content: space-around !important;
}
.align-items-start {
  -webkit-align-items: flex-start !important;
          align-items: flex-start !important;
}
.align-items-end {
  -webkit-align-items: flex-end !important;
          align-items: flex-end !important;
}
.align-items-center {
  -webkit-align-items: center !important;
          align-items: center !important;
}
.align-items-baseline {
  -webkit-align-items: baseline !important;
          align-items: baseline !important;
}
.align-items-stretch {
  -webkit-align-items: stretch !important;
          align-items: stretch !important;
}
.align-content-start {
  -webkit-align-content: flex-start !important;
          align-content: flex-start !important;
}
.align-content-end {
  -webkit-align-content: flex-end !important;
          align-content: flex-end !important;
}
.align-content-center {
  -webkit-align-content: center !important;
          align-content: center !important;
}
.align-content-between {
  -webkit-align-content: space-between !important;
          align-content: space-between !important;
}
.align-content-around {
  -webkit-align-content: space-around !important;
          align-content: space-around !important;
}
.align-content-stretch {
  -webkit-align-content: stretch !important;
          align-content: stretch !important;
}
.align-self-auto {
  -webkit-align-self: auto !important;
          align-self: auto !important;
}
.align-self-start {
  -webkit-align-self: flex-start !important;
          align-self: flex-start !important;
}
.align-self-end {
  -webkit-align-self: flex-end !important;
          align-self: flex-end !important;
}
.align-self-center {
  -webkit-align-self: center !important;
          align-self: center !important;
}
.align-self-baseline {
  -webkit-align-self: baseline !important;
          align-self: baseline !important;
}
.align-self-stretch {
  -webkit-align-self: stretch !important;
          align-self: stretch !important;
}
@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-sm-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }
  .justify-content-sm-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important;
  }
  .align-items-sm-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
  }
  .align-items-sm-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important;
  }
  .align-items-sm-center {
    -webkit-align-items: center !important;
            align-items: center !important;
  }
  .align-items-sm-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important;
  }
  .align-content-sm-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important;
  }
  .align-content-sm-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important;
  }
  .align-content-sm-center {
    -webkit-align-content: center !important;
            align-content: center !important;
  }
  .align-content-sm-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important;
  }
  .align-content-sm-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important;
  }
  .align-self-sm-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important;
  }
  .align-self-sm-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important;
  }
  .align-self-sm-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important;
  }
  .align-self-sm-center {
    -webkit-align-self: center !important;
            align-self: center !important;
  }
  .align-self-sm-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-md-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }
  .justify-content-md-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
  }
  .justify-content-md-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important;
  }
  .align-items-md-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
  }
  .align-items-md-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important;
  }
  .align-items-md-center {
    -webkit-align-items: center !important;
            align-items: center !important;
  }
  .align-items-md-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important;
  }
  .align-items-md-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important;
  }
  .align-content-md-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important;
  }
  .align-content-md-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important;
  }
  .align-content-md-center {
    -webkit-align-content: center !important;
            align-content: center !important;
  }
  .align-content-md-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important;
  }
  .align-content-md-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important;
  }
  .align-content-md-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important;
  }
  .align-self-md-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important;
  }
  .align-self-md-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important;
  }
  .align-self-md-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important;
  }
  .align-self-md-center {
    -webkit-align-self: center !important;
            align-self: center !important;
  }
  .align-self-md-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important;
  }
  .align-self-md-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-lg-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }
  .justify-content-lg-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important;
  }
  .align-items-lg-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
  }
  .align-items-lg-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important;
  }
  .align-items-lg-center {
    -webkit-align-items: center !important;
            align-items: center !important;
  }
  .align-items-lg-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important;
  }
  .align-content-lg-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important;
  }
  .align-content-lg-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important;
  }
  .align-content-lg-center {
    -webkit-align-content: center !important;
            align-content: center !important;
  }
  .align-content-lg-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important;
  }
  .align-content-lg-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important;
  }
  .align-self-lg-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important;
  }
  .align-self-lg-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important;
  }
  .align-self-lg-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important;
  }
  .align-self-lg-center {
    -webkit-align-self: center !important;
            align-self: center !important;
  }
  .align-self-lg-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-xl-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }
  .justify-content-xl-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important;
  }
  .align-items-xl-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
  }
  .align-items-xl-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important;
  }
  .align-items-xl-center {
    -webkit-align-items: center !important;
            align-items: center !important;
  }
  .align-items-xl-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important;
  }
  .align-content-xl-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important;
  }
  .align-content-xl-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important;
  }
  .align-content-xl-center {
    -webkit-align-content: center !important;
            align-content: center !important;
  }
  .align-content-xl-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important;
  }
  .align-content-xl-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important;
  }
  .align-self-xl-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important;
  }
  .align-self-xl-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important;
  }
  .align-self-xl-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important;
  }
  .align-self-xl-center {
    -webkit-align-self: center !important;
            align-self: center !important;
  }
  .align-self-xl-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important;
  }
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}
.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}
.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}
.m-n2 {
  margin: -0.5rem !important;
}
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}
.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}
.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}
.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}
.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}
.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}
.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}
.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}
.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}
.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}
.m-n5 {
  margin: -3rem !important;
}
.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}
.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}
.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}
.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace !important;
}
.text-justify {
  text-align: justify !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-lighter {
  font-weight: lighter !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-weight-bolder {
  font-weight: bolder !important;
}
.font-italic {
  font-style: italic !important;
}
.text-white {
  color: #fff !important;
}
.text-primary {
  color: #007bff !important;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #0056b3 !important;
}
.text-secondary {
  color: #6c757d !important;
}
a.text-secondary:hover,
a.text-secondary:focus {
  color: #494f54 !important;
}
.text-success {
  color: #28a745 !important;
}
a.text-success:hover,
a.text-success:focus {
  color: #19692c !important;
}
.text-info {
  color: #17a2b8 !important;
}
a.text-info:hover,
a.text-info:focus {
  color: #0f6674 !important;
}
.text-warning {
  color: #ffc107 !important;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #ba8b00 !important;
}
.text-danger {
  color: #dc3545 !important;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #a71d2a !important;
}
.text-light {
  color: #f8f9fa !important;
}
a.text-light:hover,
a.text-light:focus {
  color: #cbd3da !important;
}
.text-dark {
  color: #343a40 !important;
}
a.text-dark:hover,
a.text-dark:focus {
  color: #121416 !important;
}
.text-body {
  color: #212529 !important;
}
.text-muted {
  color: #6c757d !important;
}
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}
.text-reset {
  color: inherit !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.vw-100 {
  width: 100vw !important;
}
.vh-100 {
  height: 100vh !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}

@font-face {
  font-family: 'Restart';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/naverfont_09@1.0/Restart.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}

.intro-container-main-img {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-image: url(../../static/media/main-img.2368a826.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.intro-container-text-link {
  display: block;
  margin: 0 auto;
  width: 75%;
  padding: 12px 0;
  line-height: 1.3;
  background-color: #c181fb;
  color: white;
  text-align: center;
  font-size: 16px;
  border-radius: 20px;
  font-weight: 600;
  margin-top: 24px;
  margin-bottom: 16px;
}

.intro-container-main-text {
  text-align: center;
}

.intro-container-main-text h5 {
  font-size: 14px;
  line-height: 1.1666666667;
  margin-bottom: 28px;
}

.intro-container-text-str {
  display: block;
  font-size: 14px;
  line-height: 0.4166666667;
  color: #83081c;
  margin-bottom: 22px;
}

.intro-container-text-desc {
  font-size: 14px;
  letter-spacing: -0.02em;
  line-height: 1.5714285714;
  margin-bottom: 18px;
  color: #646464;
}

.intro-container-text-desc span {
  color: #5f0080;
  font-weight: 500;
  text-decoration: underline;
}

.intro-container-book {
  background-color: #eef2f6;
  padding: 24px 10px;
  margin-bottom: 16px;
}

.intro-container-book-box-text {
  text-align: center;
}

.intro-container-book-box-img {
  margin: 0 auto;
  width: 60%;
  height: 210px;
  background-image: url(../../static/media/intro-book-img.7b50f741.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-bottom: 4px;
}

.intro-container-book-text {
  text-align: center;
}

.intro-container-book-box-text {
  font-size: 12px;
  line-height: 1.1666666667;
  margin-bottom: 24px;
}

.intro-container-book-text h1 {
  font-size: 20px;
  letter-spacing: 0.02em;
  line-height: 1.1;
  color: #5f0080;
  margin-bottom: 24px;
}

.intro-container-book-desc {
  margin-left: 12px;
  font-size: 12px;
  line-height: 1.25;
  text-align: start;
  margin-bottom: 16px;
}

.intro-container-box-text {
  font-family: 'Restart', sans-serif;
  padding: 12px;
  letter-spacing: 0.1em;
  background-color: #5f0080;
  text-align: center;
  color: #fff;
  font-size: 22px;
  line-height: 1.2083333333;
  font-weight: 300;
  margin-bottom: 12px;
}

.intro-container-box-items {
  padding: 3px 10px;
}

.intro-container-box-item {
  border: 1px solid #ccc;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.05) 0%,
      rgba(255, 255, 255, 1e-5) 100%
    ),
    #ffffff;
  background-blend-mode: overlay, normal;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05),
    inset 0px 1px 0px rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.intro-container-box-item-img1,
.intro-container-box-item-img2,
.intro-container-box-item-img3 {
  width: 100%;
  height: 0;
  padding-bottom: 130%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: block;
  margin-bottom: 10px;
}

.intro-container-box-item-img1 {
  background-image: url(../../static/media/intro-box-img1.9db8d08d.jpg);
}
.intro-container-box-item-img2 {
  background-image: url(../../static/media/intro-box-img2.cb24650e.jpg);
}
.intro-container-box-item-img3 {
  background-image: url(../../static/media/intro-box-img3.ec13a28d.jpg);
}

.intro-container-box-item-text {
  font-family: 'Restart', sans-serif;
  text-align: center;
  font-size: 24px;
  letter-spacing: -0.03em;
  color: #707070;
  line-height: 1.1153846154;
}

@media screen and (min-width: 994px) {
  .intro-container-main-img {
    padding-bottom: 60%;
    background-image: url(../../static/media/main-img-desk.2e5f2f62.jpg);
  }

  .intro-container-text-link {
    margin-top: 48px;
    font-size: 39px;
    white-space: nowrap;
    padding: 0;
    padding-bottom: 3px;
    width: 50%;
    border-radius: 40px;
  }

  .intro-container-main-text h5 {
    font-size: 28px;
    margin-bottom: 48px;
  }

  .intro-container-text-str {
    font-size: 35px;
    margin-bottom: 70px;
  }

  .intro-container-text-desc {
    font-size: 32px;
    line-height: 1.3;
    letter-spacing: -0.02em;
    margin-bottom: 48px;
  }

  .intro-container-book-box {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 48px 56px;
  }

  .intro-container-book-box-img {
    height: 432px;
    width: 50%;
    margin-bottom: 0;
  }
  .intro-container-book-box-text {
    width: 50%;
    margin-bottom: 0;
    font-size: 30px;
    text-align: center;
    letter-spacing: 0.02em;
    line-height: 1.7;
    white-space: nowrap;
    color: #646464;
  }
  .intro-container-book-text {
    padding: 12px 100px;
  }

  .intro-container-book-text h1 {
    font-size: 44px;
    text-align: start;
    margin-left: 7px;
    font-weight: bold;
  }
  .intro-container-book-desc {
    color: #646464;
    font-size: 30px;
    line-height: 1.7;
    letter-spacing: -0.02em;
    margin-bottom: 48px;
    font-weight: 500;
  }
  .intro-container-box-text {
    font-size: 63px;
    line-height: 1.1;
    padding: 36px 0;
  }
  .intro-container-box-items {
    display: -webkit-flex;
    display: flex;
    width: 100%;
  }
  .intro-container-box-item {
    margin-right: 24px;
    -webkit-flex-shrink: 1;
            flex-shrink: 1;
    width: 33%;
  }
  .intro-container-box-item:last-child {
    margin-right: 0;
  }

  .intro-container-box-item-img1,
  .intro-container-box-item-img2,
  .intro-container-box-item-img3 {
    height: 391px;
    background-size: cover;
  }
}

/* .footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

.footer-logo {
  margin: 0 auto;
  width: 120px;
  height: 120px;
  background-image: url(../../static/media/header-logo.b63c29ca.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.footer-info {
  background-color: #383838;
  color: #777777;
  font-size: 11px;
  line-height: 1.4545454545;
  letter-spacing: 0;
  padding: 13px 10px;
}

.header-logo {
  padding-top: 20px;
}

.header-logo-img {
  width: 100%;
  height: 0;
  padding-bottom: 25%;
  background-image: url(../../static/media/header-logo.b63c29ca.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.header-menu {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  overflow-x: hidden;
  margin-bottom: 16px;
  font-weight: 500;
}

.header-menu-list {
  display: -webkit-flex;
  display: flex;
  padding: 0 12px;
  overflow-x: scroll;
  overflow-y: hidden;
}

.header-menu-list::-webkit-scrollbar {
  display: none;
}

.header-menu-item {
  margin-right: 24px;
}
.header-menu-item:last-child {
  margin-right: 0;
}

.header-menu-link {
  display: block;
  margin-top: 16px;
  font-size: 0.8em;
  white-space: nowrap;
}

/* kakaotalk */

.kakaotalk-icon,
.phone-icon {
  width: 45px;
  height: 45px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  z-index: 100;
  opacity: 0.8;
}

.kakaotalk-icon {
  position: fixed;
  right: 8px;
  top: 95px;
  background-image: url(../../static/media/kakao.0a2d36db.png);
  border-radius: 20%;
}
.phone-icon {
  width: 45px;
  height: 45px;
  position: fixed;
  right: 7px;
  top: 142px;
  background-image: url(../../static/media/call-w.f6e75971.webp);
}

@media screen and (min-width: 994px) {
  .header-logo-img {
    padding-bottom: 10%;
  }

  .header-menu {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }

  .header-menu-list {
    width: 100%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 0 12px;
  }

  .header-menu-item {
    -webkit-flex-grow: 1;
            flex-grow: 1;
  }

  .header-menu-item a {
    display: block;
    font-size: 1.3rem;
    padding: 1em 0;
    text-align: center;
    margin-top: 0;
    transition: 0.2s ease-in-out;
  }

  .header-menu-item a:hover {
    background-color: #c181fb;
    color: #fff;
  }

  .kakaotalk-icon {
    width: 80px;
    height: 80px;
    right: 47px;
    top: 200px;
    opacity: 0.6;
  }

  .phone-icon {
    width: 80px;
    height: 80px;
    right: 45px;
    top: 290px;
    opacity: 0.6;
  }

  .phone-icon:hover .phone-icon-hover {
    width: 300px;
    height: 200px;
    background-image: url(../../static/media/call-hover.9f413ac4.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: fixed;
    right: 120px;
    top: 310px;
  }
}

.after-review-img {
  width: 100%;
  height: 0;
  padding-bottom: 60%;
  background-image: url(../../static/media/after-review-img.8113e82b.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.naver-map {
  width: 100%;
  height: 300px;
  margin-bottom: 24px;
}

.map-container-title {
  font-size: 11px;
  font-weight: 600;
  color: #5f0080;
  text-align: center;
  margin-bottom: 12px;
}
.map-container-text-addr {
  color: #5f0080;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  line-height: 1.4;
  margin-bottom: 24px;
}
.map-container-text-img {
  width: 100%;
  height: 0;
  padding-bottom: 80%;
  background-image: url(../../static/media/map-img.dcc500ef.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 24px;
}
.map-container-text-desc {
  padding: 12px 18px;
  font-size: 12px;
  color: #5f0080;
  margin-bottom: 24px;
}

.map-container-text-link {
  display: block;
  margin: 0 auto;
  width: 60%;
  padding: 12px 0;
  line-height: 1.3;
  background-color: #c181fb;
  color: white;
  text-align: center;
  font-size: 16px;
  border-radius: 20px;
}

.map-container-text-link strong {
  display: none;
}

@media screen and (min-width: 994px) {
  .after-review-img {
    width: 100%;
    height: 0;
    padding-bottom: 60%;
  }
  .map-container {
    padding: 0 48px;
    position: relative;
  }

  .map-container-title {
    display: none;
  }

  .naver-map {
    width: 50%;
    height: 500px;
  }

  .map-container-text {
    width: 40%;
    position: absolute;
    right: 30px;
    top: -15px;
    white-space: nowrap;
  }
  .map-container-text-addr {
    display: block;
    font-size: 24px;
    line-height: 1.7;
    letter-spacing: -0.02em;
    margin-bottom: 24px;
  }

  .map-container-text-desc {
    margin-bottom: 24px;
  }

  .map-container-text-desc,
  .map-container-text-bus {
    padding: 0;
    font-size: 15px;
    letter-spacing: -0.05em;
  }

  .map-container-text-link {
    margin-top: 100px;
    font-size: 34px;
    white-space: nowrap;
    padding: 24px 0;
    border-radius: 40px;
  }
  .map-container-text-link strong {
    display: inline;
  }

  .map-container-text-link span {
    display: none;
  }
}

.intro-bar {
  background-color: #5f0080;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 5px 0 7px;
  margin-bottom: 24px;
}

.intro-bar-logo svg {
  fill: #fff;
  margin-right: 10px;
  margin-top: 5px;
}

.intro-bar-text {
  color: #fff;
  font-size: 19px;
}

.kind-contact-text {
  text-align: center;
}

.kind-contact-text-link {
  display: block;
  margin: 0 auto;
  width: 75%;
  padding: 12px 0;
  line-height: 1.3;
  background-color: #c181fb;
  color: white;
  text-align: center;
  font-size: 16px;
  border-radius: 20px;
  font-weight: 600;
  margin-top: 24px;
  margin-bottom: 16px;
}

.kind-contact-text h5 {
  font-size: 15px;
  line-height: 1.2666666667;
  margin-bottom: 28px;
}

@media screen and (min-width: 994px) {
  .intro-bar {
    padding: 10px 0 14px;
    margin-bottom: 48px;
  }

  .intro-bar-logo svg {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    margin-right: 24px;
    -webkit-transform: translateY(2px) scale(1.6);
            transform: translateY(2px) scale(1.6);
  }
  .intro-bar-text {
    font-size: 40px;
  }

  .kind-contact-text-link {
    margin-top: 48px;
    font-size: 39px;
    white-space: nowrap;
    padding: 0;
    padding-bottom: 3px;
    width: 50%;
    border-radius: 40px;
  }

  .kind-contact-text h5 {
    margin-bottom: 48px;
    font-size: 28px;
  }
}

.facility-container-blog-title {
  background-color: #5f0080;
  color: #fff;
  text-align: center;
  border-radius: 20px;
  padding: 12px 24px;
  margin-bottom: 16px;
}

.facility-container-blog-list {
  padding: 0 10px;
  margin-bottom: 24px;
}

.facility-container-blog-list-item {
  margin-bottom: 12px;
}
.facility-container-blog-list-item:last-child {
  margin-bottom: 0;
}
.facility-container-blog-list a {
  display: inline-block;
  font-size: 14px;
  color: #707070;
  text-decoration: underline;
  margin-left: 10px;
}

.facility-container-blog-list-item span {
  font-size: 16px;
  color: #5f0080;
}

/* Facility-intro */
.facility-intro {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 36px;
  padding: 0 2px;
}

.facility-intro-text {
  margin-right: 10px;
  white-space: nowrap;
}

.facility-intro-title {
  font-size: 20px;
  color: #5f0080;
  letter-spacing: -0.022em;
  line-height: 1.4;
  margin-bottom: 8px;
  font-weight: 500;
}

.facility-intro-desc {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 1.6;
  color: #707070;
  margin-bottom: 8px;
}

.facility-intro-sub-bar {
  font-size: 8px;
  display: inline-block;
  background-color: #5f0080;
  padding: 5px 14px;
  border-radius: 16px;
  color: #fff;
  margin-bottom: 8px;
}

.facility-intro-sub-notice {
  display: block;
  font-size: 10px;
  letter-spacing: -0.05em;
  line-height: 1.6;
  color: #707070;
}

.facility-intro-img {
  width: 50%;
  height: 0;
  padding-bottom: 50%;
  border-radius: 10px;
  background-image: url(../../static/media/facility-intro-logo.a8742226.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media screen and (min-width: 994px) {
  .facility-intro {
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 70px;
  }

  .facility-intro-text {
    width: auto;
  }
  .facility-intro-title {
    font-size: 36px;
    letter-spacing: -0.03em;
    font-weight: 500;
    margin-bottom: 36px;
  }
  .facility-intro-desc {
    font-size: 28px;
    letter-spacing: -0.03em;
    white-space: nowrap;
    margin-bottom: 48px;
  }
  .facility-intro-sub-bar {
    font-size: 24px;
    padding: 18px 36px;
  }
  .facility-intro-sub-notice {
    display: none;
  }

  .facility-img {
    padding: 0 24px;
  }

  .facility-intro-img {
    width: 40%;
    padding-bottom: 35%;
    background-image: url(../../static/media/facility-intro-logo.a8742226.jpg);
  }
}

/* Facility-img */

.facility-img {
  padding: 0 2px;
  margin-bottom: 48px;
}

.facility-img-text-title {
  font-size: 20px;
  letter-spacing: -0.022em;
  color: #5f0080;
  line-height: 1.4;
  margin-bottom: 16px;
  font-weight: 500;
}

.facility-img-text-desc {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 1.6;
  color: #707070;
  margin-bottom: 16px;
}

.facility-img-img {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 24px;
  -webkit-justify-content: center;
          justify-content: center;
}

.facility-img-img-item1,
.facility-img-img-item2,
.facility-img-img-item3,
.facility-img-img-item4,
.facility-img-img-item5,
.facility-img-img-item6 {
  width: 48%;
  height: 0;
  padding-bottom: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: 1px solid #5f0080;
  border-radius: 12px;
}

.facility-img-img-item1 {
  background-image: url(../../static/media/facility-1.96785f91.jpg);
  margin-right: 8px;
  margin-bottom: 8px;
}
.facility-img-img-item2 {
  background-image: url(../../static/media/facility-2.84452918.jpg);
  margin-bottom: 8px;
}
.facility-img-img-item3 {
  background-image: url(../../static/media/facility-3.9a23456b.jpg);
  margin-right: 8px;
  margin-bottom: 8px;
}
.facility-img-img-item4 {
  background-image: url(../../static/media/facility-4.8944a0a6.jpg);
  margin-bottom: 8px;
}
.facility-img-img-item5 {
  background-image: url(../../static/media/facility-5.01c54fe5.jpg);
  margin-right: 8px;
}
.facility-img-img-item6 {
  background-image: url(../../static/media/facility-6.060b6360.jpg);
}

@media screen and (min-width: 994px) {
  .facility-img {
    background-color: #eef2f6;
    padding: 36px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 100px;
  }

  .facility-img-text-title-br {
    display: none;
  }

  .facility-img-text-title {
    font-size: 40px;
  }

  .facility-img-text-desc {
    font-size: 30px;
    margin-bottom: 24px;
  }

  .facility-img-img-item1,
  .facility-img-img-item2,
  .facility-img-img-item3,
  .facility-img-img-item4,
  .facility-img-img-item5,
  .facility-img-img-item6 {
    height: 0;
    padding-bottom: 50%;
  }

  .facility-img-img-item1 {
    margin-right: 15px;
    margin-bottom: 15px;
  }
  .facility-img-img-item2 {
    margin-bottom: 15px;
  }
  .facility-img-img-item3 {
    margin-right: 15px;
    margin-bottom: 15px;
  }
  .facility-img-img-item4 {
    margin-bottom: 15px;
  }
  .facility-img-img-item5 {
    margin-right: 15px;
  }
}

.main-intro-img {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-image: url(../../static/media/main-img.2368a826.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 24px;
}

.main-intro-text {
  text-align: center;
  margin-bottom: 24px;
}

.main-intro-title {
  margin: 0 auto;
  width: 60%;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.02em;
  margin-bottom: 36px;
}

.main-intro-desc {
  font-size: 14px;
  line-height: 1.5714285714;
  letter-spacing: -0.02em;
  color: #646464;
  margin-bottom: 48px;
}

.main-intro-link {
  display: inline-block;
  color: #fff;
  background-color: #c181fb;
  font-size: 16px;
  padding: 8px 16px 12px;
  border-radius: 16px;
  margin-bottom: 24px;
}

@media screen and (min-width: 994px) {
  .main-intro-title {
    width: 100%;
    font-size: 1.8rem;
    line-height: 1.7;
    margin-bottom: 36px;
  }
  .main-intro-desc {
    font-size: 1.5rem;
    margin-bottom: 48px;
  }

  .main-intro-desc span {
    color: #004845;
  }
  .main-intro-link {
    font-size: 2rem;
    margin-bottom: 48px;
    padding: 12px 48px 16px;
  }

  .main-intro-img {
    width: 100%;
    height: 0;
    padding-bottom: 60%;
    margin: 0 auto;
    background-image: url(../../static/media/main-img-desk.2e5f2f62.jpg);
    margin-bottom: 36px;
  }
}

.rehabilitation-slick-container,
.care-slick-container-main,
.care-slick-container-intro {
  margin: 0 8px 56px;
  padding: 0 5px;
}

.slick-slide img {
  margin: 0 auto;
}

.slick-track {
  margin-bottom: 36px;
}

.slick-track img {
  width: 100%;
  border-radius: 20px;
}

.slick-dots li {
  margin-right: 30px;
}

.slick-dots li a {
  display: block;
}

.slick-dots li a img {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

.slick-slide div {
  outline: none;
}

/* care */

.care-slick-container-main p,
.rehabilitation-slick-container p,
.care-slick-container-intro p {
  margin-top: 10px;
  text-align: center;
  color: #5f0080;
}
.care-slick-container-main .slick-track,
.care-slick-container-intro .slick-track {
  margin-bottom: 36px;
}

.care-slick {
  display: -webkit-flex;
  display: flex;
}
.care-slick li {
  margin-right: 30px;
}

.care-slick li a img {
  width: 50px;
  height: 50px;
}

@media screen and (min-width: 994px) {
  .rehabilitation-slick-container,
  .care-slick-container-intro,
  .care-slick-container-main {
    padding: 0 48px;
    margin-bottom: 100px;
  }
  .slick-next {
    margin-right: 160px;
  }
  .slick-prev {
    margin-left: 150px;
  }

  .slick-slide img {
    margin: 0 auto;
  }

  .slick-track img {
    width: 50%;
  }

  .rehabilitation-slick-container .slick-track p {
    margin-bottom: 100px;
  }
  .slick-arrow:before {
    font-size: 33px;
    color: #5f0080;
  }

  .slick-dots li {
    margin-right: 140px;
  }

  .slick-dots li a img {
    width: 150px;
    height: 150px;
  }

  /* care */

  .care-slick-container-main p,
  .care-slick-container-intro p,
  .rehabilitation-slick-container p {
    font-size: 16px;
    font-weight: 500;
  }

  .care-slick-container-main .slick-track,
  .care-slick-container-intro .slick-track {
    margin-bottom: 150px;
  }

  .care-slick li {
    margin-right: 140px;
  }
}

.rehabilitation-intro {
  padding: 0 3px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 24px;
}

.rehabilitation-intro-text-title {
  width: 100%;
  font-size: 16px;
  letter-spacing: -0.02em;
  line-height: 1.3;
  color: #5f0080;
  font-weight: 500;
  margin-bottom: 12px;
}

.rehabilitation-intro-text-desc {
  font-size: 10px;
  letter-spacing: 0;
  line-height: 1.6;
  color: #707070;
}

.rehabilitation-intro-img {
  width: 40%;
  height: 0;
  padding-bottom: 32%;
  border-radius: 20px;
  background-image: url(../../static/media/rehabilitation-intro-logo.34899646.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media screen and (min-width: 798px) {
  .rehabilitation-intro {
    margin-bottom: 70px;
  }

  .rehabilitation-intro-text-title {
    font-size: 36px;
    margin-bottom: 24px;
  }
  .rehabilitation-intro-text-desc {
    font-size: 24px;
    letter-spacing: -0.03em;
  }
}

.care-intro {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 36px;
  padding: 0 3px;
}

.care-intro-text {
  margin-right: 30px;
  white-space: nowrap;
}

.care-intro-title {
  font-size: 20px;
  color: #5f0080;
  font-weight: 500;
  letter-spacing: -0.022em;
  line-height: 1.4;
  margin-bottom: 8px;
}

.care-intro-desc {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 1.6;
  color: #707070;
  margin-bottom: 16px;
}

.care-intro-sub-bar {
  font-size: 12px;
  display: inline-block;
  background-color: #5f0080;
  padding: 4px 14px;
  border-radius: 16px;
  color: #fff;
}

.care-intro-sub-notice {
  display: block;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 1.6;
  color: #707070;
  margin-bottom: 12px;
  text-align: center;
}

.care-intro-img {
  width: 100%;
  height: 0;
  padding-bottom: 40%;
  border-radius: 20%;
  background-image: url(../../static/media/care-intro-logo.bde0147a.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media screen and (min-width: 994px) {
  .care-intro {
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 100px;
  }

  .care-intro-title {
    font-size: 36px;
    letter-spacing: -0.03em;
    font-weight: 500;
    margin-bottom: 36px;
  }

  .care-intro-desc {
    font-size: 28px;
    letter-spacing: -0.03em;
    white-space: nowrap;
    margin-bottom: 36px;
  }

  .care-intro-sub-bar {
    font-size: 24px;
    padding: 18px 36px;
  }

  .care-intro-sub-notice {
    display: none;
  }

  .care-intro-img {
    padding-bottom: 35%;
    width: 40%;
  }
}

.facility-container-main-img {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-image: url(../../static/media/facility-main-img.69f3103c.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.facility-container-box-item-text-title {
  font-size: 20px;
  color: #5f0080;
  letter-spacing: -0.02em;
  line-height: 1.2;
  margin-bottom: 10px;
  font-weight: 500;
}
.facility-container-box-item-text-desc {
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: 0;
  margin-bottom: 15px;
  color: #707070;
  letter-spacing: -0.03em;
}

.facility-container-box-item:first-child {
  margin-top: 25px;
}

.facility-container-box-item-img1,
.facility-container-box-item-img2,
.facility-container-box-item-img3,
.facility-container-box-item-img4 {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 24px;
  border: 0;
  margin-bottom: 48px;
}

.facility-container-box-item-img4 {
  margin-bottom: 20px;
}

.facility-container-box-item-img1 {
  background-image: url(../../static/media/facility-9.03a3f979.jpg);
}
.facility-container-box-item-img2 {
  background-image: url(../../static/media/facility-10.873295f5.jpg);
}
.facility-container-box-item-img3 {
  background-image: url(../../static/media/facility-8.d9ce323e.jpg);
}
.facility-container-box-item-img4 {
  background-image: url(../../static/media/facility-6.060b6360.jpg);
}

.facility-container-info h1 {
  font-size: 18px;
  background-color: #82061b;
  color: #fff;
  text-align: center;
  border-radius: 24px;
  padding: 12px 24px;
  margin-bottom: 24px;
}

@media screen and (min-width: 994px) {
  .facility-container-main-img {
    padding-bottom: 60%;
    background-image: url(../../static/media/facility-main-img-desk.0e4c2d20.jpg);
  }

  .facility-container-box-item:first-child {
    display: none;
  }

  .facility-container-box-items {
    display: -webkit-flex;
    display: flex;
    padding: 12px;
    margin-bottom: 48px;
  }
  .facility-container-box-item {
    margin-right: 33px;
    width: 33%;
  }
  .facility-container-box-item:last-child {
    margin-right: 0;
  }

  .facility-container-box-item-text-title {
    text-align: center;
    font-size: 32px;
    padding: 0 12px;
    margin-bottom: 18px;
  }
  .facility-container-box-item-text-desc {
    font-size: 15px;
    line-height: 1.3684210526;
    padding: 0 18px;
    letter-spacing: -0.05em;
  }

  .facility-container-blog-title {
    width: 50%;
    margin: 48px auto;
    font-size: 30px;
  }

  .facility-container-blog-list {
    margin-bottom: 48px;
  }

  .facility-container-blog-list-item {
    margin-bottom: 32px;
  }

  .facility-container-blog-list-item:last-child {
    margin-bottom: 0;
  }

  .facility-container-blog-list a {
    font-size: 28px;
  }

  .facility-container-blog-list-item span {
    font-size: 30px;
  }

  .facility-container-info h1 {
    font-size: 33px;
    width: 50%;
    margin: 56px auto 70px;
  }
}

.care-container-main-img {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-image: url(../../static/media/care-main-img.415bcc21.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.care-container-box-item {
  margin-bottom: 24px;
}

.care-container-box-item-img1,
.care-container-box-item-img2,
.care-container-box-item-img3,
.care-container-box-item-img4,
.care-container-box-item-img5,
.care-container-box-item-img6,
.care-container-box-item-img7 {
  width: 100%;
  height: 250px;
  max-width: 500px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: 1px solid #5f0080;
  margin: 0 auto;
  border-radius: 12px;
  margin-bottom: 12px;
}

.care-container-box-item-img1 {
  background-image: url(../../static/media/care-1.c038c655.jpg);
}
.care-container-box-item-img2 {
  background-image: url(../../static/media/care-2.6a56aa33.jpg);
}
.care-container-box-item-img3 {
  background-image: url(../../static/media/care-3.0295c397.jpg);
}
.care-container-box-item-img4 {
  background-image: url(../../static/media/care-4.9d1dc1d1.jpg);
}
.care-container-box-item-img5 {
  background-image: url(../../static/media/care-5.d1d9320a.jpg);
}

.care-container-box-item p {
  margin-top: 3px;
  text-align: center;
  color: #5f0080;
  font-size: 12px;
  font-weight: 500;
}

.care-slick-container-main {
  display: none;
}

@media screen and (min-width: 994px) {
  .care-slick-container-main {
    margin-bottom: 100px;
  }
  .care-container-main-img {
    padding-bottom: 60%;
    background-image: url(../../static/media/care-main-img-desk.5df79d77.jpg);
    margin-bottom: 70px;
  }

  .care-main {
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 70px;
    margin-top: 70px;
  }

  .care-main-desc {
    font-size: 28px;
    letter-spacing: -0.03em;
    white-space: nowrap;
    margin-bottom: 36px;
  }

  .care-intro-sub-notice {
    display: none;
  }

  .care-slick-container-main {
    display: block;
  }

  .care-container-box-items {
    display: none;
  }

  .care-container-box-item-img1 {
    background-image: url(../../static/media/rehabilitation-intro-1.c2bded31.jpg);
  }
  .care-container-box-item-img2 {
    background-image: url(../../static/media/rehabilitation-intro-2.b2fba262.jpg);
  }
  .care-container-box-item-img3 {
    background-image: url(../../static/media/rehabilitation-intro-3.b8ca1f76.jpg);
  }
  .care-container-box-item-img4 {
    background-image: url(../../static/media/rehabilitation-intro-4.991e10f8.jpg);
  }
}

.info-container-main-img {
  width: 100%;
  height: 0;
  padding-bottom: 60%;
  background-image: url(../../static/media/info-main-img.3558a4d6.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.info-container-box-how-title,
.info-container-box-need-title,
.info-container-cost-text-title {
  font-size: 30px;
  font-weight: 500;
  letter-spacing: -0.02em;
  color: #5f0080;
  margin-bottom: 18px;
}

.info-container-box-how-desc,
.info-container-box-need-desc,
.info-container-cost-text-desc {
  font-size: 12px;
  color: #707070;
  line-height: 1.6;
  margin-bottom: 12px;
}

.info-container-cost-text-desc {
  letter-spacing: -0.05em;
}

.info-container-cost-text-desc span {
  color: #5f0080;
  text-decoration: underline;
}

.info-container-box-how-desc:last-child,
.info-container-box-need-bar:last-child {
  margin-bottom: 18px;
}

.info-container-cost-text-desc:last-child {
  margin-bottom: 36px;
}

.info-container-cost-text-desc:last-child span {
  text-decoration: none;
  font-weight: 500;
  color: #c181fb;
}

.info-container-box-how-bar,
.info-container-box-need-bar {
  display: inline-block;
  font-size: 12px;
  background-color: #5f0080;
  color: #fff;
  border-radius: 8px;
  padding: 2px 12px;
  margin-bottom: 12px;
}

.info-container-cost-text-img {
  width: 100%;
  height: 0;
  padding-bottom: 60%;
  background-image: url(../../static/media/info-cost-img.563da486.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 12px;
}

@media screen and (min-width: 994px) {
  .info-container-main-img {
    padding-bottom: 60%;
    background-image: url(../../static/media/info-main-img-desk.2332b50f.jpg);
  }

  .info-container-box {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-bottom: 48px;
  }

  .info-container-box-how,
  .info-container-box-need {
    padding: 12px 24px;
  }

  .info-container-box-how-title,
  .info-container-box-need-title,
  .info-container-cost-text-title {
    font-size: 44px;
  }

  .info-container-box-how-desc,
  .info-container-box-need-desc,
  .info-container-cost-text-desc {
    font-size: 20px;
    letter-spacing: 0.05em;
    line-height: 1.5263157895;
  }

  .info-container-box-how-bar,
  .info-container-box-need-bar {
    font-size: 26px;
    padding: 8px 24px;
    border-radius: 24px;
  }

  .info-container-box-need-bar {
    display: none;
  }

  .info-container-box-how-desc,
  .info-container-box-how-bar {
    margin-bottom: 30px;
  }

  .info-container-cost-text-img {
    height: 700px;
  }

  .info-container-cost-text-desc:last-child {
    font-size: 30px;
  }
  .info-container-cost-text-desc:last-child span {
    font-size: 44px;
    text-decoration: none;
    font-weight: 600;
    color: #82061b;
  }

  .info-container-cost-text-img {
    padding-bottom: 10%;
    background-size: contain;
  }
}

.rehabilitation-container-main-img {
  width: 100%;
  height: 0;
  padding-bottom: 65%;
  background-image: url(../../static/media/rehabilitation-main-img.fa7b557e.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media screen and (min-width: 994px) {
  .rehabilitation-container-main-img {
    width: 100%;
    padding-bottom: 70%;
    background-image: url(../../static/media/rehabilitation-main-img-desk.4987354f.jpg);
  }
}

* {
  box-sizing: border-box;
  margin: 0;
}

body {
  font-family: 'Noto Sans KR', sans-serif;
  color: #2b292d;
}

/* Reset CSS */
a {
  color: inherit;
  text-decoration: none;
}

button,
input,
textarea {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 16px;
  border: none;
}

button:focus,
button:active,
input:focus,
input:active,
textarea:focus,
textarea:active {
  outline: none;
  box-shadow: none;
}

ul,
ol,
li {
  list-style-type: none;
  padding-left: 0;
  margin-left: 0;
}

p {
  font-size: 16px;
  line-height: 1.5;
  color: #2b292d;
  letter-spacing: -0.01em;
}

/* Custom Grid System - Fix container width  */
@media screen and (min-width: 1200px) {
  .container {
    max-width: 1140px !important;
  }
}

