@font-face {
  font-family: 'Restart';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/naverfont_09@1.0/Restart.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}

.intro-container-main-img {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-image: url('../../img/main/main-img.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.intro-container-text-link {
  display: block;
  margin: 0 auto;
  width: 75%;
  padding: 12px 0;
  line-height: 1.3;
  background-color: #c181fb;
  color: white;
  text-align: center;
  font-size: 16px;
  border-radius: 20px;
  font-weight: 600;
  margin-top: 24px;
  margin-bottom: 16px;
}

.intro-container-main-text {
  text-align: center;
}

.intro-container-main-text h5 {
  font-size: 14px;
  line-height: 1.1666666667;
  margin-bottom: 28px;
}

.intro-container-text-str {
  display: block;
  font-size: 14px;
  line-height: 0.4166666667;
  color: #83081c;
  margin-bottom: 22px;
}

.intro-container-text-desc {
  font-size: 14px;
  letter-spacing: -0.02em;
  line-height: 1.5714285714;
  margin-bottom: 18px;
  color: #646464;
}

.intro-container-text-desc span {
  color: #5f0080;
  font-weight: 500;
  text-decoration: underline;
}

.intro-container-book {
  background-color: #eef2f6;
  padding: 24px 10px;
  margin-bottom: 16px;
}

.intro-container-book-box-text {
  text-align: center;
}

.intro-container-book-box-img {
  margin: 0 auto;
  width: 60%;
  height: 210px;
  background-image: url('../../img/intro/intro-book-img.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-bottom: 4px;
}

.intro-container-book-text {
  text-align: center;
}

.intro-container-book-box-text {
  font-size: 12px;
  line-height: 1.1666666667;
  margin-bottom: 24px;
}

.intro-container-book-text h1 {
  font-size: 20px;
  letter-spacing: 0.02em;
  line-height: 1.1;
  color: #5f0080;
  margin-bottom: 24px;
}

.intro-container-book-desc {
  margin-left: 12px;
  font-size: 12px;
  line-height: 1.25;
  text-align: start;
  margin-bottom: 16px;
}

.intro-container-box-text {
  font-family: 'Restart', sans-serif;
  padding: 12px;
  letter-spacing: 0.1em;
  background-color: #5f0080;
  text-align: center;
  color: #fff;
  font-size: 22px;
  line-height: 1.2083333333;
  font-weight: 300;
  margin-bottom: 12px;
}

.intro-container-box-items {
  padding: 3px 10px;
}

.intro-container-box-item {
  border: 1px solid #ccc;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.05) 0%,
      rgba(255, 255, 255, 1e-5) 100%
    ),
    #ffffff;
  background-blend-mode: overlay, normal;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05),
    inset 0px 1px 0px rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.intro-container-box-item-img1,
.intro-container-box-item-img2,
.intro-container-box-item-img3 {
  width: 100%;
  height: 0;
  padding-bottom: 130%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: block;
  margin-bottom: 10px;
}

.intro-container-box-item-img1 {
  background-image: url('../../img/intro/intro-box-img1.jpg');
}
.intro-container-box-item-img2 {
  background-image: url('../../img/intro/intro-box-img2.jpg');
}
.intro-container-box-item-img3 {
  background-image: url('../../img/intro/intro-box-img3.jpg');
}

.intro-container-box-item-text {
  font-family: 'Restart', sans-serif;
  text-align: center;
  font-size: 24px;
  letter-spacing: -0.03em;
  color: #707070;
  line-height: 1.1153846154;
}

@media screen and (min-width: 994px) {
  .intro-container-main-img {
    padding-bottom: 60%;
    background-image: url('../../img/main/main-img-desk.jpg');
  }

  .intro-container-text-link {
    margin-top: 48px;
    font-size: 39px;
    white-space: nowrap;
    padding: 0;
    padding-bottom: 3px;
    width: 50%;
    border-radius: 40px;
  }

  .intro-container-main-text h5 {
    font-size: 28px;
    margin-bottom: 48px;
  }

  .intro-container-text-str {
    font-size: 35px;
    margin-bottom: 70px;
  }

  .intro-container-text-desc {
    font-size: 32px;
    line-height: 1.3;
    letter-spacing: -0.02em;
    margin-bottom: 48px;
  }

  .intro-container-book-box {
    display: flex;
    align-items: center;
    padding: 48px 56px;
  }

  .intro-container-book-box-img {
    height: 432px;
    width: 50%;
    margin-bottom: 0;
  }
  .intro-container-book-box-text {
    width: 50%;
    margin-bottom: 0;
    font-size: 30px;
    text-align: center;
    letter-spacing: 0.02em;
    line-height: 1.7;
    white-space: nowrap;
    color: #646464;
  }
  .intro-container-book-text {
    padding: 12px 100px;
  }

  .intro-container-book-text h1 {
    font-size: 44px;
    text-align: start;
    margin-left: 7px;
    font-weight: bold;
  }
  .intro-container-book-desc {
    color: #646464;
    font-size: 30px;
    line-height: 1.7;
    letter-spacing: -0.02em;
    margin-bottom: 48px;
    font-weight: 500;
  }
  .intro-container-box-text {
    font-size: 63px;
    line-height: 1.1;
    padding: 36px 0;
  }
  .intro-container-box-items {
    display: flex;
    width: 100%;
  }
  .intro-container-box-item {
    margin-right: 24px;
    flex-shrink: 1;
    width: 33%;
  }
  .intro-container-box-item:last-child {
    margin-right: 0;
  }

  .intro-container-box-item-img1,
  .intro-container-box-item-img2,
  .intro-container-box-item-img3 {
    height: 391px;
    background-size: cover;
  }
}
