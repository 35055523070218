.care-intro {
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;
  padding: 0 3px;
}

.care-intro-text {
  margin-right: 30px;
  white-space: nowrap;
}

.care-intro-title {
  font-size: 20px;
  color: #5f0080;
  font-weight: 500;
  letter-spacing: -0.022em;
  line-height: 1.4;
  margin-bottom: 8px;
}

.care-intro-desc {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 1.6;
  color: #707070;
  margin-bottom: 16px;
}

.care-intro-sub-bar {
  font-size: 12px;
  display: inline-block;
  background-color: #5f0080;
  padding: 4px 14px;
  border-radius: 16px;
  color: #fff;
}

.care-intro-sub-notice {
  display: block;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 1.6;
  color: #707070;
  margin-bottom: 12px;
  text-align: center;
}

.care-intro-img {
  width: 100%;
  height: 0;
  padding-bottom: 40%;
  border-radius: 20%;
  background-image: url('../../img/care-intro/care-intro-logo.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media screen and (min-width: 994px) {
  .care-intro {
    justify-content: space-around;
    align-items: center;
    margin-bottom: 100px;
  }

  .care-intro-title {
    font-size: 36px;
    letter-spacing: -0.03em;
    font-weight: 500;
    margin-bottom: 36px;
  }

  .care-intro-desc {
    font-size: 28px;
    letter-spacing: -0.03em;
    white-space: nowrap;
    margin-bottom: 36px;
  }

  .care-intro-sub-bar {
    font-size: 24px;
    padding: 18px 36px;
  }

  .care-intro-sub-notice {
    display: none;
  }

  .care-intro-img {
    padding-bottom: 35%;
    width: 40%;
  }
}
