.info-container-main-img {
  width: 100%;
  height: 0;
  padding-bottom: 60%;
  background-image: url('../../img/info/info-main-img.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.info-container-box-how-title,
.info-container-box-need-title,
.info-container-cost-text-title {
  font-size: 30px;
  font-weight: 500;
  letter-spacing: -0.02em;
  color: #5f0080;
  margin-bottom: 18px;
}

.info-container-box-how-desc,
.info-container-box-need-desc,
.info-container-cost-text-desc {
  font-size: 12px;
  color: #707070;
  line-height: 1.6;
  margin-bottom: 12px;
}

.info-container-cost-text-desc {
  letter-spacing: -0.05em;
}

.info-container-cost-text-desc span {
  color: #5f0080;
  text-decoration: underline;
}

.info-container-box-how-desc:last-child,
.info-container-box-need-bar:last-child {
  margin-bottom: 18px;
}

.info-container-cost-text-desc:last-child {
  margin-bottom: 36px;
}

.info-container-cost-text-desc:last-child span {
  text-decoration: none;
  font-weight: 500;
  color: #c181fb;
}

.info-container-box-how-bar,
.info-container-box-need-bar {
  display: inline-block;
  font-size: 12px;
  background-color: #5f0080;
  color: #fff;
  border-radius: 8px;
  padding: 2px 12px;
  margin-bottom: 12px;
}

.info-container-cost-text-img {
  width: 100%;
  height: 0;
  padding-bottom: 60%;
  background-image: url('../../img/info/info-cost-img.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 12px;
}

@media screen and (min-width: 994px) {
  .info-container-main-img {
    padding-bottom: 60%;
    background-image: url('../../img/info/info-main-img-desk.jpg');
  }

  .info-container-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;
  }

  .info-container-box-how,
  .info-container-box-need {
    padding: 12px 24px;
  }

  .info-container-box-how-title,
  .info-container-box-need-title,
  .info-container-cost-text-title {
    font-size: 44px;
  }

  .info-container-box-how-desc,
  .info-container-box-need-desc,
  .info-container-cost-text-desc {
    font-size: 20px;
    letter-spacing: 0.05em;
    line-height: 1.5263157895;
  }

  .info-container-box-how-bar,
  .info-container-box-need-bar {
    font-size: 26px;
    padding: 8px 24px;
    border-radius: 24px;
  }

  .info-container-box-need-bar {
    display: none;
  }

  .info-container-box-how-desc,
  .info-container-box-how-bar {
    margin-bottom: 30px;
  }

  .info-container-cost-text-img {
    height: 700px;
  }

  .info-container-cost-text-desc:last-child {
    font-size: 30px;
  }
  .info-container-cost-text-desc:last-child span {
    font-size: 44px;
    text-decoration: none;
    font-weight: 600;
    color: #82061b;
  }

  .info-container-cost-text-img {
    padding-bottom: 10%;
    background-size: contain;
  }
}
