@import url('https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap');
* {
  box-sizing: border-box;
  margin: 0;
}

body {
  font-family: 'Noto Sans KR', sans-serif;
  color: #2b292d;
}

/* Reset CSS */
a {
  color: inherit;
  text-decoration: none;
}

button,
input,
textarea {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 16px;
  border: none;
}

button:focus,
button:active,
input:focus,
input:active,
textarea:focus,
textarea:active {
  outline: none;
  box-shadow: none;
}

ul,
ol,
li {
  list-style-type: none;
  padding-left: 0;
  margin-left: 0;
}

p {
  font-size: 16px;
  line-height: 1.5;
  color: #2b292d;
  letter-spacing: -0.01em;
}

/* Custom Grid System - Fix container width  */
@media screen and (min-width: 1200px) {
  .container {
    max-width: 1140px !important;
  }
}
